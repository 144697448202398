.zone-main {

    border-width: 2px;
    border-style: solid;
    border-radius: 15px;
    background-color: white;
    font-weight: bold;
    text-align: center;
    height: fit-content;
    width: fit-content;
    margin: 30px;
    margin-right: 40px;
    box-shadow: 5px 5px 20px rgb(205, 205, 205);
    padding: 10px 30px;
    cursor: pointer;
}

.zone {
    position: fixed;
    top: 0;
    right: 0;
}


.zone-dropdown {
    margin: 30px;
}