/*position: fixed;
    top: 0%;
    right: 30%;
    left: 30%;*/
.map-main {
  width: 500px;
  margin: auto;
}

.flex {
  display: flex;
}

.c1 {
  height: 310px;
  width: 90px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px black;
}

.c2 {
  height: 50px;
  width: 140px;
  text-align: center;
  padding: 30px 0px 0px 0px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px black;

}

.c3 {
  height: 50px;
  width: 100px;
  text-align: center;
  padding: 30px 0px 0px 0px;
  background-color: white;

  box-shadow: inset 0px 0px 0px 1px black;

}

.c4 {
  height: 150px;
  width: 140px;
  text-align: center;
  background-color: white;

  box-shadow: inset 0px 0px 0px 1px black;

}

.c5 {
  height: 80px;
  width: 100px;
  text-align: center;
  padding: 70px 0px 0px 0px;
  background-color: white;
  box-shadow: inset 0px 0px 0px 1px black;
}

.c6 {
  height: 50px;
  width: 240px;
  text-align: center;
  background-color: white;
  padding: 30px 0px 0px 0px;
  box-shadow: inset 0px 0px 0px 1px black;
}