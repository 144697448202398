/*
box-shadow: 10px 10px 50px rgb(200, 200, 200);
    margin: 40px;
    position: fixed;
    border-radius: 15px 15px 5px 5px;
    top: 0;
    left: 0;
    height: fit-content;
    width: 400px;
    overflow: hidden;
    transition-duration: 1s;
*/


@media (min-width: 601px) {
    .sidebar {
        box-shadow: 10px 10px 50px rgb(200, 200, 200);
        border-radius: 15px 15px 5px 5px;
        height: fit-content;
        width: 400px;
        margin: 0px 40px 0px 0px;
        overflow: hidden;
        transition-duration: 1s;
    }
    }

@media (max-width: 600px) {
    .sidebar{
      margin: 0px 0px 0px 0px;
      box-shadow: 10px 10px 50px rgb(200, 200, 200);
      border-radius: 15px 15px 5px 5px;
      height: fit-content;
      width: 400px;
      overflow: hidden;
      transition-duration: 1s;
    }
    }

.sidebar-image {
    width: 100%;
    margin: 0px;
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    
}
.sidebar-content {
    margin: 10px 15px;
    padding: 20px;
    text-align: left;
}

.sidebar-title {
    font-size: 30px;
    font-weight: bold;
    width: 100%;
}

.sidebar-ocupacion {
    margin-top: 20px;
    font-size: 20px;
    font-style: italic;
}

.sidebar-chart {
    margin-top: 20px;
}

.sidebar-nodata {
    text-align: center;
    width: 100%;
    height: 100%;
    margin: auto;
    font-style: italic;
}