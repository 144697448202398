.selector-main {
    filter: drop-shadow(5.06306px 5.06306px 20.2523px rgba(0, 0, 0, 0.25));
    margin: 40px;
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.selector-main div {
    border-style: solid;
    border-color: black;
}

.selector {
    border-width: 2px;
    transition-duration: 0.2s;
    border-width: 0px 2px 2px 2px;
    padding: 10px 20px;
}

.selector-active {
    background-color: #50ABFF;
    color: white;
    font-weight: bold;
}

.selector-inactive {
    background-color: white;
}

.selector-top {
    border-width: 2px 2px 2px 2px;
    border-radius: 15px 15px 0px 0px;
}

.selector-bottom {
    border-radius: 0px 0px 15px 15px;
}